import React from "react"
import Img from "gatsby-image"
import PropTypes from "prop-types"

const BlokImage = ({
  aspectRatio,
  imgTransition,
  fixed,
  fluid,
  imgStyle,
  isBackground,
  sizeMultiplier,
  wrapperStyle,
  src,
  ...rest
}) => {
  return (
    <Img
      fluid={src}
      durationFadeIn={200}
      style={{ ...wrapperStyle }}
      imgStyle={{ ...imgStyle }}
      alt="img"
      {...rest}
    />
  )
}

BlokImage.propTypes = {
  alt: PropTypes.string,
  aspectRatio: PropTypes.number,
  fadeIn: PropTypes.bool,
  fixed: PropTypes.object,
  fluid: PropTypes.object,
  imgStyle: PropTypes.object,
  imgTransition: PropTypes.string,
  isBackground: PropTypes.bool,
  sizeMultiplier: PropTypes.number,
  title: PropTypes.string,
  wrapperStyle: PropTypes.object, // When using object-fit cover, use object-position: 50% 50% for IE compatibility
}

BlokImage.defaultProps = {
  alt: "",
  aspectRatio: null,
  fadeIn: true, // use transition
  fixed: null, // These are passed onto gatsby-plugin-sharp:
  fluid: null, // https://www.gatsbyjs.org/packages/gatsby-plugin-sharp
  imgStyle: {},
  imgTransition: "opacity .65s cubic-bezier(.47,0,.745,.715)", // bielkeyang special :D
  isBackground: false,
  sizeMultiplier: 1,
  title: "",
  wrapperStyle: {},
  //Full list of props found here: https://www.gatsbyjs.org/packages/gatsby-image/
}

export default BlokImage
