import * as React from "react"
import styled from "../components/styled"
import { labels, breakpoints, breakpointsMax } from "./breakpointValues"

const AboveWrapper = styled("div")`
  @media (max-width: ${props =>
      breakpointsMax[labels.indexOf(props.breakpoint)]}) {
    display: none;
  }
  height: ${props => (props.height ? props.height : null)};
`

const BelowWrapper = styled("div")`
  @media (min-width: ${props =>
      breakpoints[labels.indexOf(props.breakpoint)]}) {
    display: none;
  }
`

const Above = ({ breakpoint, children, height, ...props }) => (
  <AboveWrapper breakpoint={breakpoint} height={height} {...props}>
    {children}
  </AboveWrapper>
)
const Below = ({ breakpoint, children, ...props }) => (
  <BelowWrapper breakpoint={breakpoint} {...props}>
    {children}
  </BelowWrapper>
)

export { Above, Below }
